<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <div id="snackbar"></div>
      <b-row class="text-center">
        <b-col sm="12">
          <img
            v-if="dashboardName == 'Ticketak'"
            class="top-logo"
            src="@/assets/image/Cineco-Logo.png"
            alt
            style="width: 30%"
          />
          <!--img v-if="dashboardName == 'MyTiketi'" class="top-logo" src="/img/mytiketi-logo.png" alt /-->
          <!-- <img class="top-logo" src="/img/empire-logo2.svg" alt=""> -->
          <!-- <img class="top-logo" src="/img/starcinema-logo.png" alt=""> -->
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col lg="4" md="6" offset-lg="4" offset-md="3" class="text-center">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form v-on:submit.prevent.self="login">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-user"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="email"
                      required
                      class="form-control"
                      placeholder="Email"
                      autocomplete="username email"
                      v-model="username"
                    />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-lock"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      required
                      class="form-control"
                      placeholder="Password"
                      autocomplete="current-password"
                      v-model="password"
                    />
                  </b-input-group>
                  <b-row>
                    <b-col cols="12">
                      <b-button type="submit" class="px-4 login-btn rounded"
                        >LOGIN</b-button
                      >
                    </b-col>
                    <!-- <b-col cols="6" class="text-right">
                      <b-button variant="link" class="px-0">Forgot password?</b-button>
                    </b-col>-->
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>

            <b-card
              no-body
              class="text-white bg-primary py-5 d-none"
              style="width: 44%"
            >
              <b-card-body class="text-center">
                <div>
                  <!-- <h2>Sign up</h2> -->
                  <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                  <!-- <b-button variant="primary" class="active mt-3">Register Now!</b-button> -->
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LoginService from "@/services/LoginService";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  mounted() {
    if (localStorage.getItem("authenticated")) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    async login() {
      
      let body = {
        UserName: this.username,
        Password: this.password,
        grant_type: "password",
      };
      let res = await LoginService.Login(body);

      if (res.Status == true) {
        console.log("Priyanshu SIngh=>",res)
        if (res.UserRole == "User") {
          var x = document.getElementById("snackbar");
          // Add the "show" class to DIV
          x.className = "show";
          x.innerHTML = "You are not authorized to access the dashboard !";
          // After 3 seconds, remove the show class from DIV
          setTimeout(function () {
            x.className = x.className.replace("show", "");
          }, 3000);
          // alert(res.message)
          localStorage.setItem("authenticated", false);
        } else {
          localStorage.setItem("authenticated", true);
          this.$cookie.set("TICHTID", res.access_token, {
            expires: res.expires_in + "s",
          });

          this.$cookie.set("TICUN", res.UserName, {
            expires: res.expires_in + "s",
          });
          localStorage.setItem("UserRole", res.UserRole);

          // TICKETAK HASH USER NAME - TTHUN
          this.secureStore.set("TTHUN", res.UserName);
          if(res.OrganizationID){
            this.secureStore.set("OrganizationID", res.OrganizationID);
          }else{
             this.secureStore.set("OrganizationID",null);
          }
          // TICKETAK USER ROLE - TTURO
          this.secureStore.set("TTURO", res.UserRole);
          localStorage.setItem("userid", res.userid);
          if (res.UserRole == "Cinema") {
            this.$router.push("/view-print-ticket");
          }  else if (res.UserRole == "EventManager") {
            this.$router.push("/reports-data/view-reports");
            // this.$router.push("/eventinfo");
          }else if (res.UserRole == "Organization") {
            if(res.OrganizationID == "3"){
              this.$router.push("/reports/accounts/details-report");
            }else if(res.OrganizationID == "7"){// 7:Mukta
              this.$router.push("/reports/accounts/mukta-accounts-report");
            }else if(res.OrganizationID == "8"){ // 8:Novo
              this.$router.push("/reports/accounts/novo-accounts-report");
            }else{
              this.$router.push("/reports/accounts/details-report");
            }
          }else if (
            res.UserRole == "Organization" ||
            res.UserRole == "Cinema" ||
            res.UserRole == "Distributor"
          ) {
            // TICKETAK USER ORGANIZATION ID - TTUOID
            if (
              res.UserRole == "Organization" &&
              res["CinemaID"] &&
              res["CinemaID"] !== "0"
            ) {
              this.secureStore.set("TTUOID", res["OrganizationID"] || "");
            } else if (
              res.UserRole == "Cinema" &&
              res["OrganizationID"] &&
              res["OrganizationID"] !== "0"
            ) {
              this.secureStore.set("TTUOID", res["CinemaID"] || "");
            } else if (
              res.UserRole == "Distributor" &&
              res["DistributorID"] &&
              res["DistributorID"] !== "0"
            ) {
              this.secureStore.set("TTUOID", res["DistributorID"] || "");
            }
            // TICKETAK USER ROLE - TTUSR
            this.secureStore.set("TTUSR", res["UserfRoleName"] || "");
            // USER PERMISSION -UPEM
            this.$cookie.set("UPEM", "adminLogs");
            localStorage.setItem("permission", "adminLogs");
            // this.$router.push("/cinemamasters");
            this.$router.push("/dashboard");
          } else if (res.UserRole == "Finance") {
            // this.$router.push("/reports");
            this.$router.push("/dashboard");
          } else {
            // this.$cookie.delete('token');
            // this.$cookie.delete('token_type');
            localStorage.setItem("authenticated", true);
            localStorage.setItem("userid", res.userid);

            this.$router.push("/dashboard");
          }
          // this.$cookie.set("TICUN", res.UserName,{
          //   expires: res.expires_in + "s"
          // });
          // localStorage.setItem("UserRole", res.UserRole);
          // this.$router.push("/dashboard");
        }
      } else {
        var x = document.getElementById("snackbar");
        // Add the "show" class to DIV
        x.className = "show";
        x.innerHTML = res.Message;
        // After 3 seconds, remove the show class from DIV
        setTimeout(function () {
          x.className = x.className.replace("show", "");
        }, 3000);
        // alert(res.message)
        localStorage.setItem("authenticated", false);
      }
    },
  },
};
</script>
<style <style scoped>
.app {
  /* background: linear-gradient(178.67deg, #E70D00 17.86%, #FA5402 126.35%) !important; */
  /* background-image: url('../../public/img/bg-bubbles.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  /*background: linear-gradient(to right, rgb(44, 2, 73) 15%, rgb(224, 0, 124));*/
  background: linear-gradient(
    to bottom,
    #686868,
    #777777,
    #878787,
    #979797,
    #a8a8a8
  );
}

#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #444d58; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1021; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  top: 30px; /* 30px from the bottom */
}
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
   However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.btn-secondary {
  border: none !important;
}
.form-control {
  border-radius: 0px;
}

/* .login-btn {
  background: linear-gradient(178.67deg, #E70D00 17.86%, #FA5402 126.35%) !important;
  color: #EFF1F8;  
  border-color: none !important;
} */

.card {
  border-radius: 0px !important;
  background: #fff;
}
.login-btn {
  /*background: linear-gradient(to right, rgb(44, 2, 73) 15%, rgb(224, 0, 124));*/
  background: #0f2027;
  border-color: none !important;
  color: #fff;
}
.top-logo {
  transform: scale(0.6);
}
</style>
